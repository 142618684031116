import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { BASE_URL, ADMIN_URL } from "../../../../../config";
import { connect } from "react-redux";
import "./UserSettings.css";
import OverlayPanel from "../overlay_panel/OverlayPanel";
import {
  Button,
  Modal,
  Panel,
  ButtonToolbar,
  Form,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
} from "react-bootstrap";
import { doLogout } from "../../../../user/login/data/actions";
import changepass from "../iconimages/change_password.svg";
import logout from "../iconimages/logout.svg";
import location from "../iconimages/location.svg";
import closebtn from "../iconimages/close_icon.svg";
import admin from "../../../map/widgets/iconimages/admin.svg";
import report from "../../../map/widgets/iconimages/report.svg";
import analytics from "../../../map/widgets/iconimages/analytics.svg";
import atlas from "../../../map/widgets/iconimages/atlas.svg";

import { snackbarActions } from "../../../../snackbar/data/action";
import { changePassword } from "../../../../user/change_password/data/action";
import LocalStorageHandler from "../../../../../util/storage";
import AuthenticationService from "../../../../../service/AuthenticationService";
class UserSettngs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        currentpsw: "",
        newpsw: "",
        confirmpsw: "",
      },
      newpswerror: "",
      confirmpswerror: "",
      showSettingsControl: false,
      show: false,
      assetUrl: false,
      analyticUrl: false,
    };
  }

  handleClose = () => {
    //console.log("reaching");
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.apiStatus !== this.props.apiStatus) {
      if (this.props.message == "Success") {
        this.props.showSnackbar("Change Password Successfully..!");
        setTimeout(() => {
          this.props.history.push("/map");
        }, 1);
      } else {
        this.props.showSnackbar(this.props.message);
      }
    }
  }

  componentDidMount() {
    this.getRoleBasedMenu();
  }
  isMember = () => {
    if (LocalStorageHandler.getLocalBody().ward_no === null) {
      return (
        <>
          <br />
          <img src={report} className="settingsImg2 iconStyle" />
          <Button
            inline="true"
            className="settingsBtn"
            onClick={this.handleReportModule}
          >
            <b>Reports</b>{" "}
          </Button>
        </>
      );
    } else {
      <></>;
    }
  };
  handleInputChange = (event) => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
    });
  };
  validateForm = (event) => {
    const reg =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,14}$/;
    const isValid = reg.test(this.state.data.newpsw);
    if (!reg.test(this.state.data.newpsw)) {
      this.setState({
        data: { ...this.state.data },
        newpswerror: "Password should be strong",
        confirmpswerror: "",
      });
      return false;
    } else if (this.state.data.newpsw != this.state.data.confirmpsw) {
      this.setState({
        data: { ...this.state.data },
        newpswerror: "",
        confirmpswerror: "Password miss match",
      });
      return false;
    } else {
      this.setState({
        data: { ...this.state.data },
        newpswerror: "",
        confirmpswerror: "",
      });
      return true;
    }
  };
  onSubmit = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      let args = {
        currentPassword: this.state.data.currentpsw,
        confirmPassword: this.state.data.confirmpsw,
      };
      this.props.changePassword(args);
    }
  };

  handleReportModule = () => {
    {
      this.props.history.push("/reportnew");
      // const win = window.open("/reportnew", "_blank");
      // win.focus();
    }
  };
  handleAtlasModule = () => {
    window.open("http://maps.ulgis.com/pdf/atlas.pdf", "_blank");
  };

  handleDataExplorerModule = async () => {
    window.open(
      "https://analysis.ulgis.com/t/ULTS/views/KWA/HOME?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
      "_blank"
    );
    // var token = await AuthenticationService.getTableauTrustToken();
    // if (token.data === "unauthorized") {
    //   this.props.showSnackbar("Not Available");
    // } else {
    //   window.open(
    //     `https://analysis.ulgis.com/trusted/${token.data}/t/IPMS/views/IPMS_Vadakara_new/Home`,
    //     "_blank"
    //   );
    // }

    //  https://<server-name>/trusted/<unique-ticket>/t/<site-name>/views/<workbook-name>/<view-name>
    // const localboby = LocalStorageHandler.getLocalBody().local_body_name;

    // if (localboby == "Vadakara Municipality") {
    //   window.open(
    //     "https://analysis.ulgis.com/t/IPMS/views/DataExplorer/Explorer?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
    //     "_blank"
    //   );
    // } else if (localboby == "Kunnothparamba Panchayath") {
    //   window.open(
    //     "https://analysis.ulgis.com/t/IPMS/views/Kunnothparamba/Home?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
    //     "_blank"
    //   );
    // } else if (localboby == "Mayyanad Panchayat") {
    //   window.open(
    //     "https://analysis.ulgis.com/t/IPMS/views/IPMS_Mayyanad/Home/vishnuprasad/b84aa851-9988-4a36-8a87-5b811772c275?:display_count=n&:showVizHome=n&:origin=viz_share_link",
    //     "_blank"
    //   );
    // } else if (localboby == "Munroethuruth Grama Panchayath") {
    //   window.open(
    //     "https://analysis.ulgis.com/t/IPMS/views/IPMS_Munroehuruthu/Home?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
    //     "_blank"
    //   );
    // } else if (localboby == "Mangattidam Grama Panchayat") {
    //   window.open(
    //     "https://analysis.ulgis.com/#/signin?redirect=%2Fsite%2FIPMS%2Fworkbooks%2F218%3F:origin%3Dcard_share_link",
    //     "_blank"
    //   );
    // }
  };

  handleUpdationModule = () => {
    {
      this.props.history.push("/form6_demo");
    }
  };
  handleAdminModule = () => {
    const token = LocalStorageHandler.getToken();
    window.open(ADMIN_URL + `map/login/` + token + "/", "_blank");
  };
  getRoleBasedMenu() {
    let userMenu = LocalStorageHandler.getMenu();
    let element = "Asset Updation";
    let elementtwo = "Data Explorer";
    {
      userMenu.map((menuItem) => {
        if (element === menuItem.menu_name) {
          if (menuItem.permission.length > 0)
            if (menuItem.permission[0].view_permission)
              this.setState({ assetUrl: true });
        } else if (elementtwo === menuItem.menu_name) {
          if (menuItem.permission.length > 0)
            if (menuItem.permission[0].view_permission)
              this.setState({ analyticUrl: true });
        }
      });
    }
  }
  render() {
    return (
      <OverlayPanel
        showControl={this.props.showSettingsControl}
        handleShowControl={this.props.handleShowSettingsControl}
        titleName="More"
      >
        <h4 className="userName">
          <b>
            {LocalStorageHandler.getUserDetails().name === ""
              ? LocalStorageHandler.getUserDetails().name
              : "Kerala Water Authority"}
          </b>{" "}
        </h4>
        <img src={location} className="localbodyImg locationIcon" />
        <span className="lcationName">
          {LocalStorageHandler.getLocalBody()
            ? LocalStorageHandler.getLocalBody().local_body_name
            : "KWA"}
        </span>
        <hr></hr>
        {/* <img src={user} className="localbodyImg iconStyle" />
        <Button
          inline="true"
          className="localbodyBtn"
          onClick={this.handleShow}
          disabled
        >
          <b>
            {LocalStorageHandler.getLocalBody()
              ? LocalStorageHandler.getLocalBody().local_body_name
              : "ദൃഷ്ടി"}
          </b>{" "}
        </Button> */}
        <React.Fragment>
          <img src={analytics} className="settingsImg2 iconStyle" />
          <Button
            inline="true"
            className="settingsBtn"
            onClick={this.handleDataExplorerModule}
          >
            <b>Analytics Portal</b>{" "}
          </Button>
          <br />
        </React.Fragment>
        <React.Fragment>
          <img src={admin} className="settingsImg2 iconStyle" />
          <Button
            inline="true"
            className="settingsBtn"
            onClick={this.handleAdminModule}
          >
            <b>Admin Module</b>{" "}
          </Button>
          {/* <br /> */}
        </React.Fragment>
        {/* <img  src={atlas} className="settingsImg2" />
        <Button
          inline="true"
          className="settingsBtn"
          onClick={this.handleAtlasModule}
        ><b>
          Atlas
       </b> </Button> */}
        {this.isMember()}
        {/* <br />
        <img  src={report} className="settingsImg2 iconStyle" />
        <Button
          inline="true"
          className="settingsBtn"
          onClick={this.handleReportModule}
        ><b>
          Reports
        </b> </Button> */}
        {/* <br />
        <img src={changepass} className="settingsImg iconStyle" />
        <Button inline="true" className="settingsBtn" onClick={this.handleShow}>
          <b> Change Password</b>{" "}
        </Button> */}
        <Modal show={this.state.show} className="cngpassmodal">
          <Panel bsStyle="primary" className="cngpass">
            <Panel.Heading>
              <Panel.Title componentClass="h3">Change Password</Panel.Title>
              <ButtonToolbar className="pull-right button">
                <img
                  src={closebtn}
                  className="panelheadbtn"
                  onClick={this.handleClose}
                />
              </ButtonToolbar>
            </Panel.Heading>
            <Panel.Body className="panelBody">
              <Form onSubmit={this.onSubmit}>
                <FormGroup controlId="name">
                  <ControlLabel>Current Password</ControlLabel>
                  <FormControl
                    type="password"
                    placeholder="Current Password"
                    value={this.state.data.currentpsw}
                    name="currentpsw"
                    onChange={this.handleInputChange}
                    required
                  />
                </FormGroup>{" "}
                <FormGroup controlId="address">
                  <ControlLabel>New Password</ControlLabel>
                  <FormControl
                    type="password"
                    placeholder="New Password"
                    value={this.state.data.newpsw}
                    name="newpsw"
                    onChange={this.handleInputChange}
                    required
                  />
                </FormGroup>
                <div>
                  <p className="errorClass">{this.state.newpswerror}</p>
                </div>
                <FormGroup controlId="email">
                  <ControlLabel>Confirm Password</ControlLabel>
                  <FormControl
                    type="password"
                    placeholder="Confirm Password"
                    value={this.state.data.confirmpsw}
                    name="confirmpsw"
                    onChange={this.handleInputChange}
                    required
                  />
                </FormGroup>{" "}
                <div>
                  <p className="errorClass">{this.state.confirmpswerror}</p>
                </div>
                <FormGroup>
                  <Button
                    type="submit"
                    bsStyle="success"
                    className="pull-right"
                  >
                    <b>Save</b>{" "}
                  </Button>{" "}
                </FormGroup>
              </Form>
            </Panel.Body>
          </Panel>
        </Modal>
        <br />
        <img src={logout} className="settingsImg iconStyle" />
        <Button
          inline="true"
          className="settingsBtn"
          onClick={this.props.doLogout}
        >
          <b>Logout</b>{" "}
        </Button>
      </OverlayPanel>
    );
  }
}
function mapStateToProps(state) {
  return {
    message: state.changePasswordReducer.message,
    apiStatus: state.changePasswordReducer.apiStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    doLogout: () => dispatch(doLogout()),
    changePassword: (data) => dispatch(changePassword(data)),
    showSnackbar: (snackbarMessage) =>
      dispatch(snackbarActions.showSnackbar(snackbarMessage)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserSettngs)
);
