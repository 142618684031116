import React, { Component } from "react";
import pin from "../goto_xy/pin.png";
import { connect } from "react-redux";
import {
  Col,
  Button,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
} from "react-bootstrap";
import "./GoToXYPanel.css";
import Feature from "ol/Feature.js";
import Point from "ol/geom/Point.js";
import { Vector as VectorSource } from "ol/source.js";
import { Vector as VectorLayer } from "ol/layer.js";
import { transform, fromLonLat } from "ol/proj";
import { Circle as CircleStyle, Stroke, Style, Icon } from "ol/style.js";
import ToolsPopupLayout from "../../../../../containers/tools_popup_layouy/ToolsPopupLayout";
class GoToXYPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      x_value: 0.0,
      y_value: 0.0,
      vectersource: null,
    };
  }
  componentDidMount() {
    var iconStyle = new Style({
      image: new Icon(
        /** @type {module:ol/style/Icon~Options} */ ({
          anchor: [0.5, 46],
          anchorXUnits: "fraction",
          anchorYUnits: "pixels",
          src: "https://openlayers.org/en/v4.6.4/examples/data/icon.png",
        })
      ),
    });
    this.setState({
      vectersource: new VectorSource({
        style: iconStyle,
        source: new VectorSource(),
      }),
    });
  }
  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  print = (event) => {
    event.preventDefault();
    //console.log(this.state.x_value + this.state.y_value);
    const { mapComponent } = this.props;
    var x = Number(this.state.x_value);
    var y = Number(this.state.y_value);
    var iconFeature = new Feature({
      geometry: new Point(transform([x, y], "EPSG:4326", "EPSG:3857")),
      name: "Null Island",
      population: 4000,
      rainfall: 500,
    });
    var iconStyle = new Style({
      image: new Icon({
        anchor: [0.5, 46],
        anchorXUnits: "fraction",
        anchorYUnits: "pixels",
        src: pin,
        scale: 0.5,
      }),
    });
    iconFeature.setStyle(iconStyle);
    var vectorSource = new VectorSource({
      features: [iconFeature],
    });
    var vectorLayer = new VectorLayer({
      source: vectorSource,
      zIndex: 1000,
      wfsstyle: new Style({
        stroke: new Stroke({
          color: "rgba(0, 0, 255, 1.0)",
          width: 2,
        }),
      }),
    });
    mapComponent.addLayer(vectorLayer);
    mapComponent
      .getView()
      .setCenter(transform([x, y], "EPSG:4326", "EPSG:3857"));
    mapComponent.getView().setZoom(19);
  };
  render() {
    return (
      <ToolsPopupLayout
        widgetName="Go To Location"
        panelheading=" Go To Location"
        collapseStyle="gotoPanels"
        panelHeadBtn="panelheadbtn"
        panelBodyStyle="gotoPanelsBody"
      >
        <Form onSubmit={this.onSubmit} horizontal>
          <FormGroup
          // controlId="formHorizontalEmail"
          >
            <Col componentClass={ControlLabel} sm={4}>
              Longitude
            </Col>
            <Col sm={8}>
              <FormControl
                type="input"
                name="x_value"
                placeholder="Enter X value"
                value={this.state.x_value}
                onChange={this.handleInputChange}
              />
            </Col>
          </FormGroup>
          <FormGroup controlId="formHorizontalEmail">
            <Col componentClass={ControlLabel} sm={4}>
              Latitude
            </Col>
            <Col sm={8}>
              <FormControl
                type="input"
                name="y_value"
                placeholder="Enter Y value"
                value={this.state.y_value}
                onChange={this.handleInputChange}
              />
            </Col>
          </FormGroup>
          <Button
            className="gotoPanelsButtons"
            bsStyle="success"
            bsSize="small"
            onClick={(event) => this.print(event)}
          >
            Submit
          </Button>
        </Form>
      </ToolsPopupLayout>
    );
  }
}
function mapStateToProps(state) {
  return {
    mapComponent: state.mapReducer.OlMap,
    legendUrls: state.mapReducer.legendUrls,
  };
}
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(GoToXYPanel);
