import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import LoginRoute from "./LoginRoute";
import PrivateRoute from "./PrivateRoute";
import Login from "../../components/user/login/Login";
import Logout from "../../components/user/logout/Logout";
import ForgotPassword from "../../components/user/forgot_password/ForgotPassword";
import MapViewer from "../../components/map_viewer/MapViewer";
import Settings from "../../components/settings/";
import UserList from "../../components/user/master/UserList";
import AddEdituser from "../../components/user/master/AddEditUser";
import LayerCategoryList from "../../components/layercategory/LayerCategoryList";
import AddEditLayerCategory from "../../components/layercategory/AddEditLayerCategory";
import LayerList from "../../components/layer/LayerList";
import AddEditLayer from "../../components/layer/AddEditLayer";
import ChangePassword from "../../components/user/change_password";
import Report from "../../components/report/Report";

class AppRoutes extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Switch>
        {/* <Route exact path="/wardselect/" component={WardSelect} /> */}
        <Route exact path="/reset-password" component={ForgotPassword} />
        <Route exact path="/" component={Login} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/logout" component={Logout} />
        <PrivateRoute exact path="/map" component={MapViewer} />
        <PrivateRoute exact path="/settings" component={Settings} />
        <PrivateRoute exact path="/reportnew" component={Report} />
        {/* <Switch> */}

        {/* <Settings>
          <Switch>
            <PrivateRoute exact path="/user/" component={UserList} />
            <PrivateRoute
              exact
              path="/user/add/"
              component={AddEdituser}
            />{" "}
            <PrivateRoute
              exact
              path="/user/edit/:user_access_id"
              component={AddEdituser}
            />
          </Switch>
          <Switch>
            <PrivateRoute
              exact
              path="/change_password/"
              component={ChangePassword}
            />
          </Switch>

          <Switch>
            <PrivateRoute
              exact
              path="/layercategory/"
              component={LayerCategoryList}
            />
            <PrivateRoute
              exact
              path="/layercategory/add"
              component={AddEditLayerCategory}
            />
            <PrivateRoute
              exact
              path="/layercategory/edit/:layercategory_id"
              component={AddEditLayerCategory}
            />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/layer/" component={LayerList} />
            <PrivateRoute exact path="/layer/add" component={AddEditLayer} />
            <PrivateRoute
              exact
              path="/layer/edit/:layer_id"
              component={AddEditLayer}
            />
          </Switch>
        </Settings> */}
      </Switch>
    );
  }
}

export default AppRoutes;
