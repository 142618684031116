import React, { Component } from "react";
import {
  Button,
  FormGroup,
  FormControl,
  Row,
  Col,
  Alert,
  Glyphicon,
  Checkbox,
} from "react-bootstrap";
// import Recaptcha from "react-recaptcha";
import localStorageHandler from "../../../util/storage/";
import { connect } from "react-redux";
import { doLogin } from "../login/data/actions";
import { withRouter } from "react-router-dom";
import Loader from "../../../components/loader/Loader";
import ForgotPasswordModel from "../forgot_password/ForgotPasswordModel";
import "./Login.css";
import Snackbar from "../../snackbar";
import { snackbarActions } from "../../snackbar/data/action";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      email: "",
      password: "",
      show: false,
      isVerified: false,
    };
  }
  handleForgotModel = () => {
    this.setState({ show: !this.state.show });
  };
  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };
  validateForm = () => {
    return this.state.username.length > 0 && this.state.password.length > 0;
  };

  recaptchaLoaded = () => {
    console.log("capcha successfully loaded");
  };

  verifyCallback = (response) => {
    if (response) {
      this.setState({
        isVerified: true,
      });
    }
  };

  handleSubmit = () => {
    // event.preventDefault();
    const userCredentials = {
      username: "public",
      password: "Ults@101",
    };
    this.props.doLogin(userCredentials);
  };

  componentDidMount() {
    this.handleSubmit();
    if (this.props.isAuthenticated) {
      this.props.history.push("/map");
    }
  }

  // componentDidMount() {
  //   const userCredentials = {
  //     "X-username": "vdk_planning",
  //     "X-password": "Vadakara@123",
  //   };
  //   this.props.doLogin(userCredentials);
  // }

  componentDidUpdate(prevprops) {
    if (
      this.props.isAuthenticated &&
      this.props.isAuthenticated !== prevprops.isAuthenticated
    ) {
      localStorageHandler.setLoginResponse(this.props.userInfo);
      this.props.history.push("/map");
    }
  }
  render() {
    return (
      // <div className="loginContainer">
      //   <Loader />
      //   <div className="withoutLogin">
      //     <div className="withoutappTitle">Loading...</div>
      //   </div>
      // </div>
      <div className="loginContainer">
        <div id="loader"></div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loggingIn: state.loginReducer.loggingIn,
    isAuthenticated: state.loginReducer.isAuthenticated,
    userInfo: state.loginReducer.userInfo,
    loginFailed: state.loginReducer.loginFailed,
    message: state.loginReducer.message,
    serverUnavilable: state.loginReducer.serverUnavilable,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    doLogin: (userCredentials) => dispatch(doLogin(userCredentials)),
    showSnackbar: (snackbarMessage) =>
      dispatch(snackbarActions.showSnackbar(snackbarMessage)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
